import React, { useState , useEffect} from 'react';
import { Container, TextField, Button, Typography, Grid, Checkbox, FormControlLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Logo from '../assets/images/Chargnex.png'; // Replace with your logo
import { message } from 'antd'; 
import axios from 'axios';
import {useNavigate} from "react-router";
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: "400px",
    margin: '0 auto', // Center the container
    height: '100vh',
    backgroundColor: '#f5f5f5',
  },
  form: {
    width: '100%',
    maxWidth: '400px',
    backgroundColor: '#fff',
    padding: theme.spacing(4),
    borderRadius: theme.spacing(1),
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
    textAlign: 'center',
  },
  logo: {
    width: '150px',
    marginBottom: theme.spacing(4),
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    backgroundColor: '#329865',
    "&:hover": {
      color: '#fff',
      backgroundColor: '#00c123 !important',
    } 
  },
  secondaryActions: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
}));

function Login() {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const authapi = `${process.env.REACT_APP_BASE_API_URL}/auth/login`;


  useEffect(() => {
    const fetchUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            if (rememberMe) {
            localStorage.setItem('userLocation', JSON.stringify({ lat: latitude, lng: longitude }));
            } else {
            sessionStorage.setItem('userLocation', JSON.stringify({ lat: latitude, lng: longitude }));
            }
          },
          (error) => {
            console.error("Error getting user's location:", error.message);
          },
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    };
    fetchUserLocation();
  }, []);

  const handleLogin = async (event) => {
    event.preventDefault();

  try {
    const response = await axios.post(authapi, {
      email,
      password
    });

    const { user, session } = response.data;
    const userDetails = {
      firstName: user.firstName,
      email: user.email,
      mobile: user.phoneNumber,
      category: response.data.userRole,
    };
    
    const userKey = `user_${user._id}`;
    const tokenKey = `token_${user._id}`;
    const rememberMeKey = `rememberMe_${user._id}`;

    // Store data based on rememberMe status
    if (rememberMe) {
      localStorage.setItem('currentUser', user._id);
      localStorage.setItem(userKey, user._id);
      localStorage.setItem(tokenKey, session.token);
      localStorage.setItem(rememberMeKey, true);
      localStorage.setItem('userDetails', JSON.stringify(userDetails));
    } else {
      sessionStorage.setItem('currentUser', user._id);
      sessionStorage.setItem(userKey, user._id);
      sessionStorage.setItem(tokenKey, session.token);
      sessionStorage.setItem('userDetails', JSON.stringify(userDetails));
    }
   
    // Redirect to dashboard or home page
    navigate("/home"); // Replace with your actual dashboard route

  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      if (error.response.data.message === "User not found.") {
        message.error("User not found.");
      } else if (error.response.data.message === "Invalid password.") {
        message.error("Invalid password.");
      }
    } else {
      console.error("Login error:", error);
      message.error("An unexpected error occurred.");
    }
  }
  };

  return (
    <div className={classes.root}>
      <Container className={classes.form} component="main">
        <img src={Logo} alt="Chargnex Logo" className={classes.logo} />
        <Typography variant="h5" gutterBottom>
          Welcome Back!
        </Typography>
        <form onSubmit={handleLogin}>
        <TextField
          variant="outlined"
          fullWidth
          label="Email"
          className={classes.input}
          style={{ marginBottom: '15px' }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextField
          variant="outlined"
          fullWidth
          label="Password"
          type="password"
          className={classes.input}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{ marginBottom: '15px' }}
          required
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
              color="primary"
            />
          }
          label="Remember Me"
          style={{ marginBottom: '10px', marginRight: "auto", width: "100%" }}
        />
        <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            className={classes.button}
          >
            Login
        </Button>
        </form>
        <Grid container className={classes.secondaryActions}>
          <Grid item>
            <Typography variant="body2" component="a" href="#" color="primary">
              <Link to="/forgot-password" style={{color:"#329865"}}>Forgot Password?</Link>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" component="a" href="#" color="primary">
              Don't have an account? Sign Up
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Login;
