import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap, InfoWindow, useLoadScript } from '@react-google-maps/api';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import bluepin from '../assets/images/Bluepin.svg';
import blackpin from '../assets/images/Blackpin.svg';

// Replace this with your actual API key
const GOOGLE_MAPS_API_KEY = 'AIzaSyDDiyJAizQKuM93iXy4_2tmpSF_avRL0Cg';

export default function Map({ chargers, selectedCharger, userLocation }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    });

    const mapRef = useRef(null);
    const clustererRef = useRef(null);
    const [activeMarker, setActiveMarker] = useState(null);
    const infoWindowRef = useRef(null);


    // Convert chargers prop to an array of locations
    const locations = chargers.map(charger => ({
        lat: parseFloat(charger.lat),
        lng: parseFloat(charger.lng),
        title: charger.siteName,
        id: charger._id,
        status: charger.status
    }));
  
    const getMarkerColor = (status) => {
        switch (status) {
            case 'available':
                return 'green';
            case 'offline':
                return 'grey';
            case 'registered':
                return '#3634F6';
            default:
                return 'red';
        }
    };
   
   
    useEffect(() => {
        if (isLoaded && mapRef.current) {
            const map = mapRef.current;

            // Clear existing markers and clusters
            if (clustererRef.current) {
                clustererRef.current.clearMarkers();
            }

            // Create markers for each charger
            const markers = locations.map(({ lat, lng, title, id, status }) => {
                const isHighlighted = selectedCharger && selectedCharger._id === id;
                const marker = new window.google.maps.Marker({
                    position: { lat, lng },
                    title,
                    icon: {
                        url: isHighlighted ? bluepin : blackpin,
                        scaledSize: isHighlighted
                            ? new window.google.maps.Size(48, 48)  // Larger size for selected marker
                            : new window.google.maps.Size(36, 36),  // Default size for other markers
                        origin: new window.google.maps.Point(0, 0),
                        anchor: new window.google.maps.Point(20, 40),
                    },
                    zIndex: isHighlighted ? 999 : 1,  // Ensure the highlighted marker is on top
                    shadow: isHighlighted
                        ? {
                            url: bluepin,  // You can use the same bluepin image or create a separate shadow image
                            scaledSize: new window.google.maps.Size(50, 50),
                        }
                        : null,
                    status: status,
                });

                return marker;
            });

            // Initialize MarkerClusterer with the new API
            const clusterer = new MarkerClusterer({
                map: map,
                markers: markers,
            });

            // Add event listener for cluster clicks
            clusterer.addListener('clustermouseclick', (event) => {
                const bounds = event.cluster.getBounds();
                map.fitBounds(bounds);
                map.setZoom(map.getZoom() + 1); // Adjust zoom level to show markers
            });

            clustererRef.current = clusterer;

            markers.forEach(marker => {
                marker.addListener('click', () => {
                    if (infoWindowRef.current) {
                        infoWindowRef.current.close();
                    }

                    const infoWindow = new window.google.maps.InfoWindow({
                        content: `
                             <div style="padding: 0; margin: 0; border-radius: 8px; background-color: #fff; box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);">
                                <h3 style="margin: 0; padding: 8px; color: #000;">${marker.getTitle()}</h3>
                                <p style="margin: 0; padding: 8px; color: ${getMarkerColor(marker.status)};">${marker.status}</p>
                                <a href="https://www.google.com/maps/search/?api=1&query=${marker.getPosition().lat()},${marker.getPosition().lng()}" target="_blank" rel="noopener noreferrer" style="display: block; padding: 8px; text-align: center; color: #1a73e8;">Open in Google Maps</a>
                            </div>
                        `,
                        position: marker.getPosition()
                    });

                    infoWindow.open(map, marker);
                    infoWindowRef.current = infoWindow; // Update the reference to the current InfoWindow
                  
                });
            });;


        }
    }, [isLoaded, locations,  chargers]);

    useEffect(() => {
        if (selectedCharger && mapRef.current) {
            const map = mapRef.current;
            map.setCenter({ lat: parseFloat(selectedCharger.lat), lng: parseFloat(selectedCharger.lng) });
            map.setZoom(15); // Zoom in to the selected charger
        }
    }, [selectedCharger]);

    if (loadError) return <div>Error loading maps</div>;
    if (!isLoaded) return <div>Loading maps...</div>;

    const mapContainerStyle = {
        width: '100%',
        height: '100%',
        borderRadius: '14px',
        minHeight: isMobile ? '400px' : '536px',
    };

    const handleMapLoad = (map) => {
        mapRef.current = map;
    };

    return (
        <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={{
                lat: parseFloat((selectedCharger && selectedCharger.lat) || userLocation.lat),
                lng: parseFloat((selectedCharger && selectedCharger.lng) || userLocation.lng ),
            }}
            zoom={12}
            onLoad={handleMapLoad}
        >
        </GoogleMap>
    );
}
