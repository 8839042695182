import React, { useState, useEffect } from 'react';
import ChargerList from '../components/chargerListComponent';
import Map from '../components/Map';
import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Logo from '../assets/images/Chargnex.png';
import Navbar from '../components/Navbar';
import axios from 'axios';

function Home() {
  const [selectedCharger, setSelectedCharger] = useState(null);
  const [filteredChargers, setFilteredChargers] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const userLocation = JSON.parse(localStorage.getItem('userLocation') || sessionStorage.getItem('userLocation'));
 
  const fetchChargers = async () => {
    if (userLocation.lat && userLocation.lng) {
      try {
        let allChargers = [];
        let page = 1;
        let hasMore = true;

        while (hasMore) {
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_API_URL}/charger/discovery`, 
            {
              params: {
                lat: userLocation.lat,
                lng: userLocation.lng,
                page: page,
                limit: 50, // Adjust the limit based on your API's maximum allowed value
              },
            }
          );

          const { list, totalCount} = response.data;
          allChargers = [...allChargers, ...list];

          if (allChargers.length >= totalCount) {
            hasMore = false;
          } else {
            page += 1;
          }
        }

        setFilteredChargers(allChargers);
      } catch (error) {
        console.error('Error fetching chargers', error);
      }
    }
  };

  useEffect(() => {
    fetchChargers();
  }, []);


  return (
    <>
      <Navbar />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '20px',
        }}
      >
        <Typography variant="h6" fontWeight={400}>
          We are
        </Typography>
        <img src={Logo} alt="Chargnex Logo" style={{ height: 50, marginBottom: 10 }} />
      </div>
      <Typography
        variant="h4"
        align="center"
        sx={{ maxWidth: '576px', margin: '0 auto', marginBottom: 4, fontSize: isMobile ? '1.2rem' : '1.5rem' }}
        style={{ fontWeight: 'bold' }}
      >
        Join us for a convenient and effortless charging experience.
      </Typography>
      <Container sx={{ marginBottom: 5, paddingTop: "0px" }}>
        <Grid container spacing={4}>
          {/* Map comes first on mobile screens */}
          <Grid item xs={12} md={6} order={isMobile ? 0 : 1} style={{ marginTop: "auto" }}>
            <Map chargers={filteredChargers} selectedCharger={selectedCharger} userLocation={userLocation} />
          </Grid>
          {/* ChargerList comes second on mobile screens */}
          <Grid item xs={12} md={6} order={isMobile ? 1 : 0}>
            <ChargerList
              chargers={filteredChargers}
              onChargerSelect={setSelectedCharger}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Home;
