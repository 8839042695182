import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Tabs, Tab, Box, IconButton, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ArrowBack, CheckCircle } from '@mui/icons-material';
import Navbar from '../components/Navbar';
import { useNavigate } from 'react-router-dom';
import "../assets/styles/profile.css";
import verifyIcon from "../assets/images/verify.svg";

function ProfilePage() {
    const [value, setValue] = useState(0);
    const [openVerifyDialog, setOpenVerifyDialog] = useState(false);
    const [verificationType, setVerificationType] = useState('');
    const [contact, setContact] = useState('');
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [contactOtp, setContactOtp] = useState('');
    const navigate = useNavigate();
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const handleVerifyClick = (type) => {
      setVerificationType(type);
      setOpenVerifyDialog(true);
    };
  
    const handleVerifyClose = () => {
      setOpenVerifyDialog(false);
    };
  
    const handleVerify = () => {
      // Handle verification logic here
      handleVerifyClose();
    };
  
    return (
      <>
        <Navbar />
        <IconButton
            edge="start"
            className="backButton"
            color="inherit"
            onClick={() => navigate("/home")}
          >
            <ArrowBack /> <span>Back to Home</span>
          </IconButton>
        <div className="root">
          <Container>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="profile tabs"
              className="tabHeader"
            >
              <Tab label="Personal Details" />
              <Tab label="My Cars" />
              <Tab label="My Bookings" />
              <Tab label="Settings" />
            </Tabs>
  
            <Box className="tabContent">
              {value === 0 && (
                <>
                  <Box className="formContainer">
                    <form className="form">
                      <Typography variant="h6" fontWeight={600} style={{ marginRight: 'auto' }}>Personal Details</Typography>
                      <TextField
                        label="Name"
                        fullWidth
                        className="inputField"
                      />
                      <TextField
                        label="Email"
                        fullWidth
                        className="inputField"
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              onClick={() => handleVerifyClick('email')}
                              className="verifyButton"
                            >
                              <img src={verifyIcon} style={{width: "20px"}} alt='Verify Icon' /> Verify
                            </IconButton>
                          ),
                        }}
                      />
                      <TextField
                        label="Contact"
                        fullWidth
                        className="inputField"
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              onClick={() => handleVerifyClick('contact')}
                              className="verifyButton"
                            >
                              <img src={verifyIcon} style={{width: "20px", color: ""}} alt='Verify Icon' /> Verify
                            </IconButton>
                          ),
                        }}
                      />
                      <Typography variant="h6" fontWeight={600} style={{ marginRight: 'auto' }}>Address</Typography>
                      <Box className="addressSection">
                        <TextField
                          label="House No"
                          fullWidth
                          className="inputField"
                        />
                        <TextField
                          label="Locality"
                          fullWidth
                          className="inputField"
                        />
                        <TextField
                          label="City"
                          fullWidth
                          className="inputField"
                        />
                        <TextField
                          label="State"
                          fullWidth
                          className="inputField"
                        />
                        <TextField
                          label="Country"
                          fullWidth
                          className="inputField"
                        />
                        <TextField
                          label="Country Code"
                          fullWidth
                          className="inputField"
                        />
                      </Box>
                    </form>
                  </Box>
                  <Box className="submitButtonContainer">
                    <Button
                      variant="contained"
                      className="submitButton"
                    >
                      Submit
                    </Button>
                  </Box>
                </>
              )}
              {value === 1 && (
                <Box>
                  <Typography variant="h6" fontWeight={600}>My Cars</Typography>
                  <Typography variant="body1">My Cars content goes here.</Typography>
                </Box>
              )}
              {value === 2 && (
                <Box>
                  <Typography variant="h6" fontWeight={600}>My Bookings</Typography>
                  <Typography variant="body1">My Bookings content goes here.</Typography>
                </Box>
              )}
              {value === 3 && (
                <Box>
                  <Typography variant="h6" fontWeight={600}>Settings</Typography>
                  <Typography variant="body1">Settings content goes here.</Typography>
                </Box>
              )}
            </Box>
          </Container>
  
          <Dialog open={openVerifyDialog} onClose={handleVerifyClose}>
            <DialogTitle>Verify {verificationType}</DialogTitle>
            <DialogContent className="dialogContent">
              {verificationType === 'email' && (
                <>
                  <Typography variant="body1">Please enter the OTP sent to your email.</Typography>
                  <TextField
                    label="OTP"
                    fullWidth
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                  />
                </>
              )}
              {verificationType === 'contact' && (
                <>
                  <Typography variant="body1">Please enter the OTP sent to your contact number.</Typography>
                  <TextField
                    label="OTP"
                    fullWidth
                    value={contactOtp}
                    onChange={(e) => setContactOtp(e.target.value)}
                  />
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleVerifyClose}>Cancel</Button>
              <Button onClick={handleVerify}>Verify</Button>
            </DialogActions>
          </Dialog>
        </div>
      </>
    );
  }
  
  export default ProfilePage;