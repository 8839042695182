import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Logo from '../assets/images/Chargnex.png'; // Replace with your logo
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    maxWidth: "400px",
    margin: '0 auto',
    backgroundColor: '#f5f5f5',
  },
  form: {
    width: '100%',
    maxWidth: '400px',
    backgroundColor: '#fff',
    padding: theme.spacing(4),
    borderRadius: theme.spacing(1),
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
    textAlign: 'center',
  },
  logo: {
    width: '150px',
    marginBottom: theme.spacing(4),
  },
  input: {
    marginBottom: "10px",
  },
  button: {
    marginTop: "10px",
  },
  message: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

function ForgotPassword() {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const navigate = useNavigate();

  const handleForgotPassword = async (event) => {
    event.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_BASE_API_URL}/auth/forgot-password`, { email });
      setEmailSent(true);
    } catch (error) {
        message.error('Failed to send reset email. Please check the email address and try again.');    }
  };

  const handleRedirect = () => {
    navigate('/reset-password'); // Redirect to reset password page
  };

  return (
    <div className={classes.root}>
      <Container className={classes.form} component="main">
        <img src={Logo} alt="Chargnex Logo" className={classes.logo} />
        <Typography variant="h5" gutterBottom>
          Forgot Password
        </Typography>
        <Typography variant="body1" paragraph>
          Enter your email address and we will send you a link to reset your password.
        </Typography>
        <TextField
          variant="outlined"
          fullWidth
          label="Email"
          className={classes.input}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ marginBottom: '15px' }}
          required
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          className={classes.button}
          onClick={handleForgotPassword}
        >
          Send Reset Link
        </Button>
        {emailSent && (
          <Box className={classes.message}>
            <Typography variant="body1" color="success.main">
              An email with a password reset link has been sent successfully.
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              className={classes.button}
              onClick={handleRedirect}
            >
              Go to Reset Password
            </Button>
          </Box>
        )}
      </Container>
    </div>
  );
}

export default ForgotPassword;
