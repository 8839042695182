import React, { useState, useEffect } from 'react';
import { TextField, InputAdornment, Button, List, ListItem, Typography, IconButton, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/system';
import chargerSymbol from '../assets/images/chargerSymbol.svg';
import { useMediaQuery } from '@mui/material';

const FilterButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(1),
  textTransform: 'none',
  backgroundColor: "#fff",
  color: "#000",
  borderRadius: "15px",
  boxShadow: "0px 0px 6px 0px #000",
  minWidth: "80px",
  padding: "0px 5px",
  marginRight: "20px",
  fontSize: "16px",
  '&.active': {
    backgroundColor: "#2aa0e9",
    color: theme.palette.common.white,
  },
  '&:hover': {
    backgroundColor: "#2aa0e9 !important",
    color: theme.palette.common.white,
  },
}));

const ChargerItem = styled(ListItem)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  margin: theme.spacing(1, 0),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
  '&.active': {
    backgroundColor: "#E3E3E3 !important"
  }
}));

function ChargerList({ chargers, onChargerSelect }) {
  const [search, setSearch] = useState('');
  const [typeFilter, setTypeFilter] = useState('');
  const [filteredChargers, setFilteredChargers] = useState(chargers);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    filterChargers(search, typeFilter);
  }, [chargers, search, typeFilter]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleTypeFilter = (type) => {
    setTypeFilter((prevType) => (prevType === type ? '' : type));
  };

  const filterChargers = (searchTerm, type) => {
    let filtered = chargers;

    if (type) {
      filtered = filtered.filter((charger) => charger.connectorType === type);
    }

    if (searchTerm) {
      filtered = filtered.filter((charger) =>
        charger.deviceName?.includes(searchTerm)
      );
    }

    setFilteredChargers(filtered);
  };

  return (
    <div>
      <Box mb={2}>
        <Typography variant='h6' style={{ marginBottom: "10px", fontSize: isMobile ? '14px' : '16px', animation: 'blink 1s infinite' }}>Select Charger Type</Typography>
        <FilterButton
          onClick={() => handleTypeFilter('Type1')}
          className={typeFilter === 'Type1' ? 'active' : ''}
        >
          Type 1
        </FilterButton>
        <FilterButton
          onClick={() => handleTypeFilter('Type2')}
          className={typeFilter === 'Type2' ? 'active' : ''}
        >
          Type 2
        </FilterButton>
        <FilterButton
          onClick={() => handleTypeFilter('CC2')}
          className={typeFilter === 'CC2' ? 'active' : ''}
        >
          CC2
        </FilterButton>
      </Box>
      <Box style={{ backgroundColor: "#ECECEC", padding: "20px", borderRadius: "10px" }}>
        <TextField
          value={search}
          onChange={handleSearchChange}
          fullWidth
          label="Search by Location"
          variant="outlined"
          margin="normal"
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ position: 'absolute', right: "15px" }}>
                <SearchIcon style={{ height: "40px", width: "40px", color: isFocused ? '#007F3F' : '#5E5E5E' }} />
              </InputAdornment>
            ),
          }}
        />
        <List style={{ height: '400px', overflow: 'auto' }}>
          {filteredChargers.map((charger) => (
            <ChargerItem button key={charger.id} onClick={() => onChargerSelect(charger)}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                <img src={chargerSymbol} alt="Charger" style={{ width: 30, height: 30 }} />
                <Box>
                  <Typography variant="h6" component="div">
                    <span style={{ fontWeight: 'bold', fontSize: isMobile ? '0.85rem' : '1.25rem' }}>
                      {charger.deviceName} |
                    </span>
                    <span style={{ fontWeight: '400', fontSize: isMobile ? '0.75rem' : '1rem', marginLeft: '8px' }}>
                      {charger.connectorType}
                    </span>
                  </Typography>
                  <Typography variant="caption" color="textSecondary" sx={{ fontWeight: "600", fontSize: isMobile ? '0.75rem' : '0.95rem' }}>{charger.location}</Typography>
                </Box>
              </div>
              <Typography color={charger.status === 'available' ? 'green' : charger.status === 'offline' ? 'grey' : charger.status === 'registered' ? '#3634F6' : 'red'}>
                {charger.status}
              </Typography>
            </ChargerItem>
          ))}
        </List>
      </Box>
    </div>
  );
}

export default ChargerList;
