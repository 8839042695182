import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  Box,
} from '@mui/material';
import { Menu as MenuIcon, Close as CloseIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import Logo from "../assets/images/ChargnexLogo.png";
import { useNavigate } from 'react-router-dom';
import ProfileIcon from '../assets/images/ProfileIcon.svg';

function Navbar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const isMenuOpen = Boolean(anchorEl);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
     // Clear session storage
  sessionStorage.clear();
  localStorage.removeItem("userDetails");
  sessionStorage.removeItem("userDetails");

    const currentUserId = localStorage.getItem("currentUser");
    if (currentUserId) {
      localStorage.removeItem(`user_${currentUserId}`);
      localStorage.removeItem(`token_${currentUserId}`);
      localStorage.removeItem(`rememberMe_${currentUserId}`);
      localStorage.removeItem("currentUser");
    }

    navigate("/");
    handleMenuClose();
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawer = (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <IconButton onClick={toggleDrawer(false)} style={{ marginLeft: 'auto' }}>
        <CloseIcon />
      </IconButton>
      <List>
        <ListItem button component={Link} to="/home">
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button component={Link} to="https://chargnex.com/">
          <ListItemText primary="Contact for business" />
        </ListItem>
        <ListItem button component={Link} to="https://support.chargnex.com/support.html">
          <ListItemText primary="Help and Support" />
        </ListItem>
        <ListItem button component={Link} to="/profile">
          <ListItemText primary="Profile" />
        </ListItem>
        <ListItem button onClick={handleLogout}>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <AppBar position="static" style={{  marginBottom: isMobile ? "20px" : "0px", color: '#000', backgroundColor: '#fff' }}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <Link to="/home" style={{ textDecoration: 'none', color: 'black' }}>
            <img src={Logo} alt="Logo" style={{ height: isMobile ? 30 : 40 }} />
          </Link>
        </Typography>
        {isMobile ? (
          <>
            <IconButton edge="end" color="inherit" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
              {drawer}
            </Drawer>
          </>
        ) : (
          <>
            <Box display="flex" alignItems="center">
              <Button color="inherit" component={Link} to="https://chargnex.com/" style={{ marginRight: 30, fontSize: 16 }}>
                Contact for business
              </Button>
              <Button color="inherit" component={Link} to="https://support.chargnex.com/support.html" style={{ marginRight: 30, fontSize: 16 }}>
                Help and Support
              </Button>
              <IconButton
                edge="end"
                color="inherit"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenuOpen}
                style={{ marginRight: 30 }}
              >
                <img src={ProfileIcon} alt="Profile Icon" style={{ height: 50 }} />
              </IconButton>
            </Box>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={isMenuOpen}
              onClose={handleMenuClose}
              
            >
              <MenuItem onClick={handleMenuClose}>
                <Link to="/profile" style={{ textDecoration: 'none', color: 'black' }}>Profile</Link>
              </MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
