import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Logo from '../assets/images/Chargnex.png'; // Replace with your logo
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd'; // Import Ant Design's message component

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    maxWidth: "400px",
    margin: '0 auto',
    backgroundColor: '#f5f5f5',
  },
  form: {
    width: '100%',
    maxWidth: '400px',
    backgroundColor: '#fff',
    padding: theme.spacing(4),
    borderRadius: theme.spacing(1),
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
    textAlign: 'center',
  },
  logo: {
    width: '150px',
    marginBottom: theme.spacing(4),
  },
  input: {
    marginBottom: "10px",
  },
  button: {
    marginTop: "10px",
  },
}));

function ResetPassword() {
  const classes = useStyles();
  const [passcode, setPasscode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  const handleResetPassword = async (event) => {
    event.preventDefault();
    if (newPassword !== confirmPassword) {
      console.error('Passwords do not match');
      return;
    }
    try {
      await axios.post(`${process.env.REACT_APP_BASE_API_URL}/auth/reset-password`, {
        code: passcode,
        newPassword,
        confirmPassword,
      });
      message.success('Password reset successfully');
      navigate('/'); // Redirect to login page after successful password reset
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        message.error(error.response.data.message);
      } else {
        message.error('Error resetting password');
      }
    }
  };

  return (
    <div className={classes.root}>
      <Container className={classes.form} component="main">
        <img src={Logo} alt="Chargnex Logo" className={classes.logo} />
        <Typography variant="h5" gutterBottom>
          Reset Password
        </Typography>
        <Typography variant="body1" paragraph>
          Enter the passcode you received in your email and set a new password.
        </Typography>
        <TextField
          variant="outlined"
          fullWidth
          label="Passcode"
          className={classes.input}
          value={passcode}
          onChange={(e) => setPasscode(e.target.value)}
          required
          style={{ marginBottom: '15px' }}
        />
        <TextField
          variant="outlined"
          fullWidth
          label="New Password"
          type="password"
          className={classes.input}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
          style={{ marginBottom: '15px' }}
        />
        <TextField
          variant="outlined"
          fullWidth
          label="Confirm Password"
          type="password"
          className={classes.input}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          style={{ marginBottom: '15px' }}
          required
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          className={classes.button}
          onClick={handleResetPassword}
        >
          Reset Password
        </Button>
      </Container>
    </div>
  );
}

export default ResetPassword;
