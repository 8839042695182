import axios from "axios";

const whoamiApi = `${process.env.REACT_APP_BASE_API_URL}/auth/who-am-i`;

export const isTokenExpired = async (token) => {
  if (!token) return true;

  try {
    await axios.get(whoamiApi, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return false;
  } catch (error) {
    if (error.response && error.response.data.message === "Unauthorized.") {
      return true;
    }
    
    return true; 
  }
};
