import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Login from './pages/login';
import Home from './pages/home';
import ProtectedRoute from './components/protectedRouteComp';
import ForgotPassword from './pages/forgotPassword';
import ResetPassword from './pages/resetPassword';
import ProfilePage from './pages/profile';

// Custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#007F3F',
    },
    secondary: {
      main: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    fontSize: 14,
    button: {
      textTransform: 'none',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route
              path="/home"
              element={<ProtectedRoute component={Home} />}
            />
          <Route
              path="/profile"
              element={<ProtectedRoute component={ProfilePage} />}
            />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
