import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { isTokenExpired } from "../utils/auth"; 

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const [isValid, setIsValid] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const validateTokenAndRole = async () => {
      const currentUserId = localStorage.getItem("currentUser") || sessionStorage.getItem("currentUser");

      if (!currentUserId) {
        setIsChecked(true);
        return;
      }

      const tokenKey = `token_${currentUserId}`;
      const token = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);

      if (!token) {
        setIsChecked(true);
        return;
      }

      const tokenExpired = await isTokenExpired(token);
      if (tokenExpired) {
        localStorage.removeItem(tokenKey);
        localStorage.removeItem("currentUser");
        sessionStorage.removeItem(tokenKey);
        sessionStorage.removeItem("currentUser");
      } else {
        setIsValid(true);
      }

      setIsChecked(true);
    };

    validateTokenAndRole();
  });

  if (!isChecked) {
    return null; 
  }

  if (!isValid) {
    return <Navigate to="/" />;
  }

  return <Component {...rest} />;
};

export default ProtectedRoute;
